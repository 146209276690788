@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

:root {
  --background: white;
  --text-primary: rgb(60, 60, 60);
  --text-secondary: rgb(120, 120, 120);
  --accent: rgb(255, 246, 192);
}
[data-theme="dark"] {
  --background: rgb(30, 30, 30);
  --text-primary: rgb(235, 235, 235);
  --text-secondary: rgb(200, 200, 200);
  --accent: rgba(255, 246, 192, 0.5);
}


.main{color:var(--text-primary);background-color:var(--background);font-size:large;font-weight:bold;padding:20px;height:calc(100vh - 40px);transition:all .5s}.switcher{color:var(--text-primary);background-color:var(--background);float:right;transition:all .5s;outline:none;border:none}.icon{color:var(--text-primary)}.home-button{float:right}.header{padding-bottom:48px}.mid-section{max-width:600px;width:80%;margin:0 auto;font-size:16px;font-weight:400;line-height:24px}.links-section{justify-content:space-between}.section{display:flex}.section-title{flex-grow:0;padding-right:32px;color:var(--text-secondary)}.section-text{flex-grow:1}.link{color:inherit;padding-right:12px}.styled-link{color:inherit;text-decoration:none;background-color:var(--accent)}.citadel:hover{transition:400ms;background-color:#08235a;color:#fff}.meta:hover{transition:400ms;background-color:#0063bf;color:#fff}.nuro:hover{transition:400ms;background-color:#313131;color:#fff}.tesla:hover{transition:400ms;background-color:#e82127;color:#fff}.toronto:hover{transition:400ms;background-color:#002a5c;color:#fff}.iai:hover{transition:400ms;background-color:#308cfb;color:#fff}.cat:hover{transition:400ms;background-color:#b49c80;color:#fff}

