@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

:root {
  --background: white;
  --text-primary: rgb(60, 60, 60);
  --text-secondary: rgb(120, 120, 120);
  --accent: rgb(255, 246, 192);
}
[data-theme="dark"] {
  --background: rgb(30, 30, 30);
  --text-primary: rgb(235, 235, 235);
  --text-secondary: rgb(200, 200, 200);
  --accent: rgba(255, 246, 192, 0.5);
}
